import Color from 'color'

import { APP_STAGE } from './helpers/appStage'
import { RM } from './tools/ramda'

/**
 * background color of the whole app
 * returns different color for different app environments
 */
export const getEnvAppBgColor = () => {
  switch (APP_STAGE.stage) {
    case `stage`:
    case `stageBeta`:
      return `#3e222f`
    case `dev`:
      return `#1e1f1e`

    case `prod`:
    case `local`:
    default:
      return `#222f3e`
  }
}

/**
 * color of the main screen navigation button
 * returns different color for different app environments
 */
export const getEnvNavButtonBgColor = () => {
  switch (APP_STAGE.stage) {
    case `stage`:
    case `stageBeta`:
      return Colors.red.dark
    case `dev`:
      return Colors.green.dark

    case `prod`:
    case `local`:
    default:
      return Colors.speedlo.main
  }
}

export const Colors = {
  black: {
    pure: '#000000',
    main: '#2e3131',
  },
  white: {
    pure: '#FFFFFF',
    main: '#f0f5f5',
  },
  grey: {
    lightest: '#EBEDEF',
    light: '#DDDFE3',
    lightish: '#BFC5CB',
    medium: '#969FA9',
    darkMedium: '#6E7A87',
    darkish: '#485463',
    dark: '#334458',
    // disabledLight: '',
    // disabledDark: '',
  },
  red: {
    light: '#FFA7A7',
    main: '#ee5253',
    dark: '#b44649',
    // dark: '#D92C2C',
    // dark: '#AC1414',
    // dark: '#A60020',
  },
  green: {
    // light: '#72C77E',
    light: '#aae4b8',
    main: '#21B383',
    dark: '#289957',
  },
  blue: {
    light: '#00C2FF',
    main: '#005DB8',
    dark: '#002775',
    darkest: '#002D7D',
  },
  greyBlue: {
    light: '#F0F0F0',
    main: '#C6CBCF',
    dark: '#9aa7af',
  },
  yellow: {
    light: '#eaf11b',
    main: '#fdd853',
    darkish: '#F2B000',
    dark: '#D2941B',
  },
  orange: { main: '#ec7d4f' },
  violet: { main: '#4E557C' },
  maroon: { main: '#724153' },
  turquoise: { main: '#017277' },
  speedlo: {
    main: '#E86A57',
    secondary: `hsl(226, 63%, 20%)`,
  },

  // colors taken from external sources
  // like integrated services
  external: {
    foodora: `#DF1067`,
    wolt: `rgba(0,157,224,1)`,
    bolt: `hsl(151, 58%, 46%)`,
  },

  beColors: {
    yellow: '#fdd853',
    magenta: '#d2386c',
    yellowGreen: '#98bc62',
    green: '#009a34',
    turquoise: '#009a8e',
    blue: '#276cf4',
    red: '#ac1414',
    camel: '#c59b6b',
  },

  // #1ca7c4 bright turquoise
  // #009a8e turquoise
  // #98bc62 yellow green
  // #7cdac2 bermuda
  // #d2386c magenta
  // #bd5560 watermelon
  // #d0e7dd mint
  // #6c8679 jade
  // #b44649 tomato red
  // #c59b6b camel
  // #f9dcd8 icy pink
  // #e0d2b7

  transparent: 'rgba(0,0,0,0)',

  appBg: getEnvAppBgColor(),
  iconColor: getEnvAppBgColor(),
} as const

export const applyAlphaToColor = RM.curry(
  (color: TColor, alphaValue: number) => {
    if (color === Colors.transparent) {
      return color
    }

    return Color(color).alpha(alphaValue).string()
  },
)

export enum Radiuses {
  Card = '0.2rem',
  Button = '0.2rem',
  TextComponent = '0.25rem',
  rounded = `50ch`,
  medium = `10px`,
}

export const Shadows = {
  light: '0px 0px 4px 0px rgba(0, 0, 0, 0.1)',
  lightBottom: '0px 2px 4px 0px rgba(0, 0, 0, 0.2)',
  lightHover: '0px 0px 20px 0px rgba(0, 0, 0, 0.1)',
  medium: '0px 0px 6px 0px rgba(0, 0, 0, 0.3)',
  heavyBottom: `0px 16px 13px -15px rgba(0, 0, 0, 0.5)`,
  longBottom: `0px 4px 8px 0px rgba(0, 0, 0, 0.05)`,
} as const

const privateSpacing = {
  appButtonHeight: 3.4375,
  appHeaderHeight: 4.4375,
  appSizeUnit: 'rem',
}

// use these values for consistent margins, paddings, gaps etc.
const spaceGaps = {
  extraSmall: '0.25rem',
  small: '0.5rem',
  smallMedium: '0.7rem',
  medium: '1rem',
  large: '1.5rem',
  extraLarge: '3rem',
}

export const Spacing = {
  MarginAroundFormComponents: '0.1rem',
  Small: '0.25rem',
  Middle: '0.5rem',
  LayoutGridCellMargin: spaceGaps.medium,
  ButtonHeight: privateSpacing.appButtonHeight,
  ButtonHeightWithUnit: `${privateSpacing.appButtonHeight}${privateSpacing.appSizeUnit}`,
  DefaultInputWidth: 20,
  DefaultLabelWidth: 7,
  DefaultSizeUnit: privateSpacing.appSizeUnit,
  ButtonColumnWidthPx: 55,
  ButtonColumnMobileWidthPx: 35,
  PageHeaderHeight: privateSpacing.appHeaderHeight,
  PageHeaderHeightWithUnit: `${privateSpacing.appHeaderHeight}${privateSpacing.appSizeUnit}`,
  PageHeaderVerticalPadding:
    (privateSpacing.appHeaderHeight - privateSpacing.appButtonHeight) / 2,
  PageHeaderVerticalPaddingWithUnit: `${
    (privateSpacing.appHeaderHeight - privateSpacing.appButtonHeight) / 2
  }${privateSpacing.appSizeUnit}`,

  formElementHeight: '2.375rem',
  formElementVerticalGap: spaceGaps.extraSmall,

  dashboardHeaderGap: spaceGaps.extraLarge,

  ...spaceGaps,
} as const

export const FontSizes = {
  InfoMessageFontSize: '1.375rem',
  CompactCard: '0.95rem',
  CompactCardBig: '1rem',
}

export const getPxInRem = () => {
  // ! FIXME
  return 16
}
