import { APP_STAGE } from './helpers/appStage'

export const appConfig = {
  fallbackLanguage: 'en',
  supportedLanguages: [
    'cs',
    'sk',
    'en',
    'pl',
    'de',
    'ru',
    'es',
    'fr',
    'af',
    'zu',
    'uk',
  ],
  endpoint: 'https://app.dev.speedlo.cloud',
  getEndpointApp: (appName: string) => `https://${appName}.dev.speedlo.cloud`,
  googleMapsApi: 'AIzaSyCjtNmagw28ylsvUe4M5Zinln_6WZYGcN0',
  persistenceKey: 'admin@local',
  pageTitleSuffix: 'LOCAL',
  wikiUrl: `https://speedlo.notion.site/speedlo/speedlo-0d6bfe00e44448f1b524c970f60c6dcd`,
  // switch to this if developing outside of whitelisted IP addresses
  // mapsEndpoint: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  mapsEndpoint:
    'https://osm.speedlo.cloud/styles/klokantech-basic/{z}/{x}/{y}.png',
  mapsEndpointBase: 'https://osm.speedlo.cloud',
  mapsStyleUrl: 'https://osm.speedlo.cloud/styles/klokantech-basic/style.json',
  speedloPrinterLink: `https://printer.speedlo.cloud/speedlo_printer_updater/SpeedloPrinterUpdater.exe`,
  anyDeskLink: `https://1sl.cz/anydesk`,
  sentryDSN: '',
  speedloSupportMail: `podpora@speedlo.cz`,
  speedloSupportPhone: `+420461049231`,
}

if (APP_STAGE.isDev) {
  Object.assign(appConfig, {
    persistenceKey: 'admin@dev',
    pageTitleSuffix: 'DEV',
    sentryDSN: 'https://8838fede172840779e9f26fed441e44c@sentry.io/1354462',
    mapsEndpoint:
      'https://osm.speedlo.cloud/styles/klokantech-basic/{z}/{x}/{y}.png',
  })
}

if (APP_STAGE.isStage) {
  Object.assign(appConfig, {
    endpoint: 'https://app.stage.speedlo.cloud',
    getEndpointApp: (appName: string) =>
      `https://${appName}.stage.speedlo.cloud`,
    persistenceKey: 'admin@stage',
    pageTitleSuffix: 'STAGE',
    sentryDSN: 'https://8838fede172840779e9f26fed441e44c@sentry.io/1354462',
    mapsEndpoint:
      'https://osm.speedlo.cloud/styles/klokantech-basic/{z}/{x}/{y}.png',
  })
}

if (APP_STAGE.isStageBeta) {
  Object.assign(appConfig, {
    endpoint: 'https://app.stage2.speedlo.cloud',
    getEndpointApp: (appName: string) =>
      `https://${appName}.stage2.speedlo.cloud`,
    persistenceKey: 'admin@stage-beta',
    pageTitleSuffix: 'STAGE-BETA',
    sentryDSN: 'https://8838fede172840779e9f26fed441e44c@sentry.io/1354462',
    mapsEndpoint:
      'https://osm.speedlo.cloud/styles/klokantech-basic/{z}/{x}/{y}.png',
  })
}

if (APP_STAGE.isProd) {
  Object.assign(appConfig, {
    endpoint: 'https://c2e-p1.deep-vision.cloud',
    getEndpointApp: (appName: string) => `https://${appName}.speedlo.cloud`,
    persistenceKey: 'admin@prod',
    pageTitleSuffix: 'speɘdlo',
    sentryDSN: 'https://8838fede172840779e9f26fed441e44c@sentry.io/1354462',
    mapsEndpoint:
      'https://osm.speedlo.cloud/styles/klokantech-basic/{z}/{x}/{y}.png',
  })
}

//This env property 'REACT_APP_BACKEND_ENDPOINT' sets endpoint to any backend defined in property. Is used in docker/kubernetes
if (process.env.REACT_APP_BACKEND_ENDPOINT) {
  Object.assign(appConfig, {
    endpoint: process.env.REACT_APP_BACKEND_ENDPOINT,
  })
}
