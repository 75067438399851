import _ from 'lodash'
import { Instance, SnapshotIn, types } from 'mobx-state-tree'

import { TSuggestion } from '../../../components/AddressSuggest'
import {
  TFAddress,
  TFCompanyBranch,
  TFOrderCustomer,
  TInputOrderResolvedAddressType,
  TMAcceptCashRegisterOrderVariables,
  TMCreateOrderAcceptVariables,
  TMCreateOrderNewVariables,
  TMCreateOrderUpdateVariables,
  TPaymentGateEnum,
  TPaymentTypeEnum,
} from '../../../graph/generated'
import { faker } from '../../../helpers/faker'
import { formatAddressIntoLines } from '../../../helpers/formats'
import { BaseModel } from '../../../models/BaseModel'
import { TCreateOrderCall } from '../createorder.types'
import { TDeliveryPriceType } from '../molecules/OrderFormDeliveryType'

type TAddOnProduct = { categoryId: ID; recipeId: ID }
type TOrderSideDishControlResult = {
  sideDishes: RoA<TAddOnProduct>
  note: Nullable<string>
  itemQuantity: number
  isConfirmed: boolean
}

export type TCall = Omit<TCreateOrderCall, 'isRinging' | 'eventId'> & {
  eventId?: ID
}

export type TOrderFormValues = {
  note?: string
  phone?: string
  email?: string
  deliverAt?: Date
  lastName?: string
  firstName?: string
  phonePrefix?: string
  paymentMethod?: NullableID
  deliveryPriceTypeId: NullableID
}

type TVolatileProps = {
  call?: TCall
  orderId: NullableID
  sideDishRootId?: ID
  minimalPrice?: string
  deliveryMinutes?: number
  values: TOrderFormValues
  fakeAddress?: TSuggestion
  sideDishSelection?: RoA<ID>
  notDeliveryLocation: boolean
  deliveryAddress?: TSuggestion
  fakeValues?: Partial<TOrderFormValues>
  // ! remove next 2 lines when BE is ready to accept paymentMethodID in finishOrder
  paymentTypeEnum: Nullable<TPaymentTypeEnum>
  paymentGateEnum: Nullable<TPaymentGateEnum>
  sideDishMode: 'none' | 'recipeSides' | 'item' | 'recipeAmountOnly'
  vamCustomer: Nullable<TFOrderCustomer>
  deliveryPriceType: Nullable<TDeliveryPriceType>
}

export const CreateOrderModel = BaseModel.named('CreateOrder')
  .props({
    branchId: types.maybe(types.string),
  })

  .volatile<TVolatileProps>(getDefaultProps)

  .actions(self => ({
    invalidate() {
      self.branchId = undefined
      Object.assign(self, getDefaultProps())
    },
  }))

  .views(self => ({
    get currentBranch(): TFCompanyBranch {
      return (
        self.root.user.findBranchById(self.branchId!) ||
        self.root.user.defaultBranch
      )
    },
  }))

  .views(self => ({
    get deliveryType() {
      return self.deliveryPriceType?.deliveryType
    },

    get warePriceType() {
      return self.deliveryPriceType?.warePriceType
    },

    get isDelivery() {
      return self.deliveryPriceType?.deliveryType === `MESSENGER`
    },

    get deliverAt() {
      return self.values.deliverAt
    },

    get hasAddress() {
      return Boolean(self.deliveryAddress || self.fakeAddress)
    },

    get gps() {
      if (this.isDelivery && this.hasAddress) {
        if (self.deliveryAddress && self.deliveryAddress.gps) {
          return clearGps(self.deliveryAddress.gps)
        } else if (self.fakeAddress && self.fakeAddress.gps) {
          return clearGps(self.fakeAddress.gps)
        }
      }

      return { ...clearGps(self.currentBranch.gps) }
    },

    get resolvedAddress(): TInputOrderResolvedAddressType | undefined {
      if (this.isDelivery && this.hasAddress) {
        if (self.deliveryAddress) {
          return clearAddress(self.deliveryAddress)
        } else if (self.fakeAddress) {
          return clearAddress(self.fakeAddress)
        }
      }

      return undefined
    },

    get paymentType(): TPaymentTypeEnum {
      return self.paymentTypeEnum ? self.paymentTypeEnum : `CASH`
    },

    get paymentGate(): TPaymentGateEnum {
      return self.paymentGateEnum ? self.paymentGateEnum : `CASH`
    },

    get phone() {
      if (self.values.phone !== undefined) {
        return self.values.phone
      }

      return self.call ? self.call.phone : ''
    },

    get phonePrefix() {
      if (self.values.phonePrefix !== undefined) {
        return self.values.phonePrefix
      }

      if (self.call) {
        return self.call.prefix
      }

      if (self.currentBranch.address.country) {
        return self.currentBranch.address.country.phonePrefix
      }

      return '+'
    },

    isPhoneInputValid() {
      // * naive phone validation, can be improved later to consider international formats
      return this.phone.length === 9 && this.phonePrefix.length === 3
    },

    get customer() {
      const formValues = this.formValues as TOrderFormValues

      return {
        lastName: formValues.lastName,
        firstName: formValues.firstName,
        emails: formValues.email ? [{ email: formValues.email }] : null,
        phones: formValues.phone
          ? [
              {
                phone: `${formValues.phonePrefix}${formValues.phone}`,
              },
            ]
          : null,
      }
    },

    get formValues() {
      if (self.vamCustomer !== null) {
        const { firstName, lastName, email } = self.vamCustomer

        return _.defaults(
          { ...self.values },
          {
            email,
            lastName,
            firstName,
            phone: this.phone,
            phonePrefix: this.phonePrefix,
          },
        )
      }

      if (self.fakeValues) {
        return _.defaults({ ...self.values }, { ...self.fakeValues })
      }

      return _.defaults(
        { ...self.values },
        { phone: this.phone, phonePrefix: this.phonePrefix },
      )
    },

    get hasOrder() {
      return self.orderId !== null
    },
  }))

  .views(self => ({
    get createOrderVariables(): TMCreateOrderNewVariables {
      return {
        gps: self.gps,
        deliverAt: self.deliverAt,
        branchId: self.currentBranch.id,
        resolvedAddress: self.resolvedAddress,
        deliveryType: self.deliveryPriceType?.deliveryType,
        warePriceType: self.deliveryPriceType?.warePriceType ?? `DELIVERY`,
        callEventId: self.call && self.call.eventId,
      }
    },

    get updateOrderVariables(): TMCreateOrderUpdateVariables {
      return {
        gps: self.gps,
        orderId: self.orderId!,
        customer: self.customer,
        deliverAt: self.deliverAt,
        resolvedAddress: self.resolvedAddress,
        deliveryType: self.deliveryPriceType?.deliveryType,
        warePriceType: self.deliveryPriceType?.warePriceType,
      }
    },

    get acceptOrderVariables(): TMCreateOrderAcceptVariables {
      return {
        orderId: self.orderId!,
        customer: self.customer,
        deliverAt: self.deliverAt,
        note: self.formValues.note,
        paymentType: self.paymentType,
        paymentGate: self.paymentGate,
      }
    },

    get acceptCashRegisterOrderVariables(): TMAcceptCashRegisterOrderVariables {
      return {
        orderId: self.orderId!,
        customer: self.customer,
        note: self.formValues.note,
        paymentType: self.paymentType,
        paymentGate: self.paymentGate,
      }
    },
  }))

  .actions(self => ({
    resetOrder() {
      self.orderId = null
    },

    resetModel() {
      Object.assign(self, getDefaultProps())
    },

    setOrder(orderId: ID) {
      self.orderId = orderId

      return orderId
    },
  }))

  .actions(self => ({
    useLocation(address: TSuggestion) {
      self.deliveryAddress = address
    },

    clearLocation() {
      self.deliveryAddress = undefined
    },

    setMinimalPrice(price: string | undefined) {
      self.minimalPrice = price
    },
  }))

  .actions(self => ({
    selectBranch(branchId: ID) {
      self.branchId = branchId
    },

    userIsCalling(call: TCall | undefined) {
      self.call = call
    },

    setDeliveryMinutes(minutes: number | undefined) {
      self.deliveryMinutes = minutes
    },

    setPaymentValues(
      paymentType: Nullable<TPaymentTypeEnum>,
      paymentGate: Nullable<TPaymentGateEnum>,
    ) {
      // ! remove this action when BE is ready to accept paymentMethodID in finishOrder
      self.paymentTypeEnum = paymentType
      self.paymentGateEnum = paymentGate
    },

    setDeliveryPriceType(deliveryPriceType: Nullable<TDeliveryPriceType>) {
      if (!deliveryPriceType) {
        return
      }

      self.deliveryPriceType = deliveryPriceType
    },

    updateFormValues(
      values: TOrderFormValues,
      touched: { [K in keyof TOrderFormValues]?: boolean },
    ) {
      self.values = {
        deliveryPriceTypeId: values.deliveryPriceTypeId,
        ..._.pick(values, Object.keys(touched)),
        deliverAt: values.deliverAt,
      }

      self.log('updated values: %j', self.values)
    },

    updateFromVam(vamCustomer: Nullable<TFOrderCustomer>) {
      self.vamCustomer = vamCustomer
      self.log('vam customer: %j', vamCustomer)
    },

    updateFormWithFakeValues() {
      self.fakeValues = {
        phonePrefix: '+420',
        email: faker.internet.email(),
        lastName: faker.name.lastName(),
        firstName: faker.name.firstName(),
        note: faker.lorem.sentence().slice(0, 50 - 1),
        phone: faker.phone.phoneNumberFormat(3).replace(/\s/g, ''),
      }

      const { firstLine, secondLine } = formatAddressIntoLines(FAKE_ADDRESS)

      self.fakeAddress = {
        ...FAKE_ADDRESS,
        firstLine: firstLine ?? ``,
        secondLine,
        gps: {
          latitude: 50.2129839,
          longitude: 15.8342836,
        },
      }
    },
  }))

  .actions(self => {
    let resolver: Nullable<(result: TOrderSideDishControlResult) => void> = null

    const makeResolver = () => {
      return new Promise<TOrderSideDishControlResult>(resolve => {
        resolver = resolve
      })
    }

    return {
      selectAmountForRecipe(recipeId: ID) {
        self.sideDishMode = 'recipeAmountOnly'
        self.sideDishRootId = recipeId

        return makeResolver()
      },

      selectSidesAndAmountForRecipe(recipeId: ID) {
        self.sideDishMode = 'recipeSides'
        self.sideDishRootId = recipeId

        return makeResolver()
      },

      selectSidesForItem(itemId: ID) {
        self.sideDishMode = 'item'
        self.sideDishRootId = itemId

        return makeResolver()
      },

      sideDishSelectionConfirmed(
        sideDishes: RoA<TAddOnProduct>,
        itemQuantity: number,
        itemNote: Nullable<string>,
      ) {
        self.sideDishMode = 'none'
        self.sideDishRootId = undefined

        // if we used null instead of empty string here, BE would not clear the item note on item edit
        const note = itemNote?.trim() ?? ''

        if (resolver) {
          resolver({
            note,
            sideDishes,
            itemQuantity,
            isConfirmed: true,
          })

          resolver = null
        }
      },

      sideDishSelectionCancel() {
        self.sideDishMode = 'none'
        self.sideDishRootId = undefined

        if (resolver) {
          resolver({
            note: null,
            sideDishes: [],
            itemQuantity: 0,
            isConfirmed: false,
          })

          resolver = null
        }
      },
    }
  })

const FAKE_ADDRESS: TFAddress = {
  state: '',
  venue: '',
  street: 'Opletalova',
  houseNumber: '334/2',
  city: 'Hradec Králové',
  country: `Česko`,
}

function getDefaultProps(): TVolatileProps {
  return {
    orderId: null,
    call: undefined,
    vamCustomer: null,
    sideDishMode: 'none',
    paymentTypeEnum: null,
    paymentGateEnum: null,
    fakeValues: undefined,
    fakeAddress: undefined,
    deliveryPriceType: null,
    minimalPrice: undefined,
    sideDishRootId: undefined,
    deliveryAddress: undefined,
    deliveryMinutes: undefined,
    notDeliveryLocation: false,
    sideDishSelection: undefined,
    values: {
      note: undefined,
      phone: undefined,
      email: undefined,
      lastName: undefined,
      firstName: undefined,
      deliverAt: undefined,
      paymentMethod: undefined,
      deliveryPriceTypeId: null,
    },
  }
}

function clearGps({ latitude, longitude }: GeoPoint) {
  return { latitude, longitude }
}

function clearAddress({
  city,
  street,
  houseNumber,
  state,
  venue,
  country,
}: TSuggestion) {
  return { city, street, houseNumber, state, venue, country }
}

export interface TCreateOrderModel extends Instance<typeof CreateOrderModel> {}
export interface TCreateOrderModelProps
  extends SnapshotIn<typeof CreateOrderModel> {}
